<template>
  <div>
    <div class="setting-box">
      <el-form
        v-if="user"
        v-model="user"
        ref="form"
        label-width="80px"
        @submit.native.stop.prevent="userFormSubmit('user')"
      >
        <h3>用户端小程序设置</h3>
        <el-form-item
          prop="appid"
          label="Appid"
        >
          <el-input
            type="text"
            v-model.trim="user.body.appid"
          ></el-input>
        </el-form-item>
        <el-form-item
          prop='secret'
          label="Secret"
        >
          <el-input
            type="text"
            show-password
            v-model.trim="user.body.secret"
          ></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-button
            type="primary"
            size="medium"
            native-type="submit"
            :loading="userLoading"
          >保存</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="setting-box">
      <el-form
      v-if="mch"
        ref="form"
        v-model="mch"
        label-width="80px"
      >
        <h3>商户端小程序设置</h3>
        <el-form-item
          prop='appid'
          label="Appid"
        >
          <el-input
            type="text"
            v-model.trim="mch.body.appid"
          ></el-input>
        </el-form-item>
        <el-form-item
          prop='secret'
          label="Secret"
        >
          <el-input
            type="text"
            show-password
            v-model.trim="mch.body.secret"
          ></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-button
            type="primary"
            size="medium"
            :loading="mchLoading"
            @click="mchFormSubmit"
          >保存</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>

</template>

<script>
import SettingService from '@admin/services/SettingService'
import flatry from '@admin/utils/flatry'

export default {
  name: 'MiniProgram',
  data () {
    return {
      userLoading: false,
      mchLoading: false,
      user: null,
      mch: null
    }
  },
  methods: {
    userFormSubmit (e) {
      this.editCreate(this.user)
    },
    mchFormSubmit (e) {
      this.editCreate(this.mch)
    },
    async editCreate (param, success, callback) {
      const { data } = await flatry(SettingService.edit(param))

      if (data) {
        this.$message.success(data.msg)
        this.$router.replace({ path: '/setting/mini-program' })
      }
    }
  },
  async mounted () {
    const { data } = await flatry(SettingService.edit())
    if (data.data.miniProgram.user) {
      this.user = data.data.miniProgram.user
    } else {
      this.user = {
        type: 1,
        body: {
          appid: '',
          secret: ''
        }
      }
    }
    if (data.data.miniProgram.mch) {
      this.mch = data.data.miniProgram.mch
    } else {
      this.mch = {
        type: 2,
        body: {
          appid: '',
          secret: ''
        }
      }
    }
  }
}
</script>
<style lang='scss' scoped>
.setting-box {
    margin-bottom: 50px;
    padding: 24px;
    border: 1px solid #ebebeb;
    border-radius: 3px;
    transition: 0.2s;
    box-shadow: 0 0 8px 0 rgba(232, 237, 250, 0.6),
        0 2px 4px 0 rgba(232, 237, 250, 0.5);
    h3 {
        margin-left: 80px;
        font-size: 1.06em;
    }
}
</style>
